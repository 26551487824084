import * as React from 'react';
import { Typography } from '@mui/material';

function Footer() {
  return (
    <footer style={{position: "fixed", bottom: 0, flexDirection: "row", display: "flex", justifyContent: "space-between", width: "100%"}}>
      <Typography sx={{ paddingLeft: "2%", paddingBottom: "1%", color: "#333" }}>
        © 2023
      </Typography>
      <Typography sx={{ paddingRight: "2%", paddingBottom: "1%" }}>
        <a style={{ color: "#2463eb", cursor: "pointer", textDecoration: "none", fontWeight: "bold" }} href="https://cgrittner.notion.site/Website-Imprint-2e9a0c20b9f44602b7a75fb49717c65f">Imprint</a>
      </Typography>
    </footer>
  );
}
export default Footer;