import React from 'react';
import './App.css';
import { Box } from '@mui/material';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Content from './components/Content';

export default function App() {
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <NavBar />
      <Content />
      <Footer />
    </Box>
  );
}