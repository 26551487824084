import "./Content.css";
import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import Blob from "./Blob";
import utum from "../assets/utum.png";

export default function Content() {
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100%" }}
        padding={0}
      >
        <Grid item sm={12} md={5} className="content-grid-text" justifyContent="center" alignItems="center" marginRight={0} padding={0}>
          <Typography className="content-grid-text-slogan" sx={{ fontFamily: "Arial,sans-serif", lineHeight: "1.1" }}>
            Data-driven decisions<br />made within seconds.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2463eb",
              marginTop: "2%",
              "&:hover": {
                backgroundColor: "#2463eb",
              },
            }}
            size="large"
            href="https://3689lmunzio.typeform.com/to/kTptlzcd"
          >
            Contact Us
          </Button>
        </Grid>

        <Grid item sm={12} md={2} justifyContent="left" alignItems="center" padding={0}>
          <Blob />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            padding: "1%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="video-container">
            <iframe
              // width="860"
              // height="490"
              className="video"
              src="https://www.youtube.com/embed/B3J7NR6goRM"
              title="Decyde"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </Grid>

        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Typography sx={{ fontSize: "30px", fontFamily: "Arial,sans-serif", fontWeight: "400", lineHeight: "1.1" }}>Affiliated with</Typography>
        </Grid>

        <Grid item xs={12} sx={{ alginItems: "center", justifyContent: "center", textAlign: "center" }}>
          <img src={utum} style={{height: "90px", width: "150px"}} />
        </Grid>
        
      </Grid> 
    );
}