import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import logo from '../assets/decyde_logo.png';

function NavBar() {
  return (
    <AppBar position="static" sx={{ backgroundColor: "#FFFFFF", width: "100%" }} elevation={0} >
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          <img src={logo} style={{height: "45px", width: "132px"}} />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;