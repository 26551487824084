import "./Blob.css";
import React from "react";
import Goo from 'gooey-react';

export default function Blob() {
  return (
    <div style={{ width: "100%" }}>
      <Goo intensity="strong" style={{ justifyAlign: "center", justifyContent: "center", display: "flex" }}>
        <svg role="img" aria-label="Example of a gooey effect" style={{height: "60%", width: "70%"}}>
          <g style={{ animation: 'rotate_back 9s linear infinite' }}>
            <circle
              cx="50%"
              cy="50%"
              r="63"
              fill="#2463eb"
              style={{
                animation: 'blob_four 12s ease-in-out -3s infinite alternate',
              }}
            />
            <circle
              cx="50%"
              cy="50%"
              r="76"
              fill="#2463eb"
              style={{
                animation: 'blob_three 9s ease-in-out -3s infinite alternate',
              }}
            />
            <circle
              cx="50%"
              cy="50%"
              r="60"
              fill="#2463eb"
              style={{
                animation: 'blob_two 6s ease-in-out -3s infinite alternate',
              }}
            />
            <circle
              cx="50%"
              cy="50%"
              r="48"
              fill="#2463eb"
              style={{
                animation: 'blob_one 12s ease-in-out -3s infinite alternate',
              }}
            />
          </g>
        </svg>
      </Goo>
    </div>
  );
}